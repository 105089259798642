import { bindable, bindingMode, children } from 'aurelia-framework';
import { ComboboxOption } from '../combobox-option/combobox-option';

@children({ name: 'options', selector: 'combobox-option' })
export class Combobox {

    @bindable({
        defaultBindingMode: bindingMode.twoWay,
    }) public value: string;

    @bindable() public label: string;
    @bindable() public placeholder: string = '';
    @bindable() public searchPlaceholder: string = 'Search';
    @bindable() private onSelect: (option: any) => void;
    @bindable() private onSearch: (query: string) => void;
    @bindable() private disableClear: boolean = false;
    @bindable() private disabled: boolean = false;
    @bindable() private valid: boolean;
    @bindable() private enableSearch: boolean = true;
    @bindable() private noResultsMessage: string = 'No results.';
    @bindable() private enableCaret: boolean = true;

    private options: ComboboxOption[];
    private input: HTMLInputElement;
    private visible: boolean = false;
    private query: string;
    private debounce: number = 400;
    private isLoading: boolean = true;
    private display: HTMLElement;
    private topPosition: number;
    private highlightedIndex: number = undefined;

    public show(): void {
        if (this.disabled) return;

        const position = this.display.getBoundingClientRect();
        this.topPosition = position.height - 1;

        this.visible = true;
        if (this.input) this.input.focus();
    }

    public hide(): void {
        this.visible = false;
    }

    public clear(): void {
        this.value = null;
    }

    public setSelectedByValue(value: string): void {
        this.value = null;
        this.value = value;
    }

    private attached(): void {
        document.getElementsByTagName('body')[0].addEventListener('click', (e) => this.hideOnClickOutside(e));
    }

    private detached(): void {
        document.getElementsByTagName('body')[0].removeEventListener('click', (e) => this.hideOnClickOutside(e));
    }

    private hideOnClickOutside(event: any): void {
        if (!event.target.closest('.combobox-options-container')) this.hide();
    }

    private toggleVisibility(event: any): void {
        if (this.disabled) return;

        // Prevent, when clicking on the clear button, selecting an option
        event.stopPropagation();

        const position = this.display.getBoundingClientRect();
        this.topPosition = position.height - 1;

        this.visible = !this.visible;
        setTimeout(() => {
            if (this.input) this.input.focus();
        });
    }

    private async search(event: any): Promise<void> {
        if (event.key === 'ArrowUp' || event.key === 'ArrowRight' || event.key === 'ArrowDown' || event.key === 'ArrowLeft' || event.key === 'Enter') {
            return;
        }

        this.isLoading = true;
        this.highlightedIndex = undefined;
        if (this.onSearch) this.onSearch(this.query);
    }

    private select(event: any, option: ComboboxOption): void {
        event.stopPropagation();
        this.value = option ? option.value : null;

        if (this.onSelect) this.onSelect(option);
        this.hide();

        // Reset data
        this.query = null;
        this.highlightedIndex = undefined;
        if (this.onSearch) this.onSearch(this.query);
    }

    private optionsChanged(): void {
        this.options = this.options.filter((x) => !x.placeholder);
        this.isLoading = false;
    }

    private getLabel(value: string): string {
        // Ignore function if we don't have a value
        if (value === null || value === undefined) return;
        // Try to find the matching option
        const option = this.options.find((x: ComboboxOption) => x.value.toString() === value.toString());
        // If we don't have a matching option we assume the value is not available
        // in the options array. Reset the value and ignore the rest of the function.
        if (option === null || option === undefined) return value;
        return option.label;
    }

    private onKeyDown(event: any): boolean {
        if (event.key === 'ArrowUp') {
            // Highlight the previous

            if (this.highlightedIndex === undefined) this.highlightedIndex = 0;
            else if (this.highlightedIndex > 0) {
                this.highlightedIndex--;
                this.scrollToHighlighted();
            }

            return false;
        }

        if (event.key === 'ArrowDown') {
            // Highlight the next

            if (this.highlightedIndex === undefined) this.highlightedIndex = 0;
            else if (this.highlightedIndex > -1 && this.highlightedIndex < this.options.length - 1) {
                this.highlightedIndex++;
                this.scrollToHighlighted();
            }

            return false;
        }

        if (event.key === 'Enter') {
            // Select the highlighted

            if (this.highlightedIndex > -1) {
                const option = this.options[this.highlightedIndex];
                this.select(event, option);
            }

            return false;
        }

        return true;
    }

    private scrollToHighlighted(): void {
        setTimeout(() => {
            const highlightedElements = document.getElementsByClassName('highlighted');
            if (highlightedElements.length > 0) {
                const highlighted = highlightedElements.item(0);
                highlighted.scrollIntoView({ block: 'center' });
            }
        });
    }
}
