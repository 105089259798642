import { autoinject, bindable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import moment from 'moment';
import {
    BusinessMap,
    StatsApiClient,
    TotalPerUrgency,
    TotalsPerCategoryPerDay
} from 'services/cyber-api';
import { StateApi } from 'services/state-api';
import { Toast } from 'utilities/toast';
import { Utilities } from 'utilities/utilities';
import { IDateRange } from '../../range-filter/range-filter';
import { PointOptionsObject } from 'highcharts';
import { ThemeColors } from '../../../../resources/theme/theme-colors';

/**
 * ThreatHistory widget displays the evolution of the ARI, per category - over a specific daterange.
 */
@autoinject
export class ThreatsHistory {
    /**
     * If vesselIds are bound through the UI/html, it filters only on these particular vessels.
     */
    @bindable() private dateRange: IDateRange;
    @bindable() private businessMap: BusinessMap;
    private threatCategories: string[];
    private series: Highcharts.SeriesHeatmapOptions[];
    private options: Highcharts.Options = {
        tooltip: {
            useHTML: true,
            formatter() {
                // Tooltip presentation, based on both the point values + point.name (see mapSeries())
                return `<strong>${this.point.value} occurrences</strong> on <br>${moment(new Date(this.point.x)).format('Do MMM YYYY')}<br><br>${this.point.name}`;
            }
        },
        plotOptions: {
            series: {
                events: {
                    click: (e) => {
                        // Extract the date + category and redirect to threat inbox to show a filtered overview

                        // Create a from-until time range for the clicked point's date; start and end of the day
                        const from = e.point.x;
                        const until = moment(e.point.x).endOf('day').valueOf();

                        const category = this.lookupThreatTypeCategory(e.point.y);
                        // Filter on all threats within the threats inbox as clicked occurrences could no longer be active
                        const status = 'all';

                        // Prepare the nav params for filtering on the threats inbox
                        const navParams: any = { status, category, from, until };
                        // When this chart is based off of a businessMap, include the vessel to filter on as well
                        if (this.businessMap)
                            navParams.vessel = this.businessMap.ttSiteId;

                        this.router.navigateToRoute('threats', navParams);
                    }
                },
                dataLabels: {
                    enabled: true,
                    useHTML: true,

                    /**
                     * Determine the 'size' of the point; this is based on the amount (total occurrences)
                     * see also: https://api.highcharts.com/highcharts/plotOptions.column.dataLabels.formatter
                     */
                    formatter() {
                        const maxPx = 35;   // = 100%
                        const minPx = 10;   // <= 10%
                        const emptyPx = 5;  // = 0% (no threats found)

                        const maxValue = Math.max(...this.series.points.map((p) => p.value));

                        // Calculate the size of the data label's circle based on the max possible size in the series
                        const percentage = this.point.value / maxValue;

                        let size = maxPx * percentage;
                        if (size <= minPx) size = minPx;

                        // Exception, if no threat was found
                        if (this.point.value === 0) size = emptyPx;

                        // The HTML required to render and center the data label circle
                        return `
                            <span class="d-flex align-items-center justify-content-center" style="height: 35px; width: 35px;">
                                <div class="threat-point" style="z-index 5; background-color: ${this.point.color}; height: ${size}px; width: ${size}px;"></div>
                            </span>
                        `;
                    }
                }
            }
        }
    };
    private loading = true;

    constructor(
        private router: Router,
        private state: StateApi,
        private statsApi: StatsApiClient
    ) {
    }

    private async bind(): Promise<void> {
        await this.refresh();
    }

    /**
     * Requires a manual refresh to be called from the consumer.
     * By default, it loads for the entire fleet but by providing a BusinessMap it only fetches stats for that vessel.
     */
    public async refresh(): Promise<void> {
        this.loading = true;

        const data = await this.retrieveData();

        // Retrieve categories from dataset to use as y-axis labels
        const threatCategories = [...new Set(data.map((x) => x.category))];
        this.threatCategories = threatCategories.sort((a, b) => a < b ? 1 : -1);
        this.options.yAxis = {
            categories: this.threatCategories
        };

        const mappedData = this.mapData(data);
        this.series = [
            {
                name: 'Heat',
                borderWidth: 10,
                type: 'heatmap',
                data: mappedData
            }
        ];

        this.loading = false;
    }

    private async dateRangeChanged(newValue, oldValue): Promise<void> {
        await this.refresh();
    }

    private async businessMapChanged(newValue, oldValue): Promise<void> {
        await this.refresh();
    }

    private async retrieveData(): Promise<TotalsPerCategoryPerDay[]> {
        const from = this.dateRange.from.startOf('day').toDate();
        const until = this.dateRange.until.endOf('day').toDate();

        try {
            if (this.businessMap)
                // Retrieve data for a single vessel
                return await this.statsApi.historyStatsByVessel(
                    this.businessMap.ttSiteId,
                    this.state.company(),
                    from,
                    until
                );
            else
                // Retrieve data for the entire fleet
                return await this.statsApi.historyThreatStats(
                    this.state.company(),
                    from,
                    until
                );
        } catch (error) {
            Toast.statsApiError();
            throw error;
        }
    }

    private lookupThreatTypeId(category: string): number {
        return this.threatCategories.findIndex((v) => v === category);
    }

    private lookupThreatTypeCategory(id: number): string {
        return this.threatCategories[id];
    }

    private getEpochsBetweenDates(startDate: Date, endDate: Date, intervalInMs: number = 86400000): number[] {
        // 86400000 milliseconds = 1 day (24 hours)

        const epochs: number[] = [];
        const currentDate = new Date(startDate);

        while (currentDate <= endDate) {
            epochs.push(currentDate.getTime());
            currentDate.setTime(currentDate.getTime() + intervalInMs);
        }

        return epochs;
    }

    private mapData(stats: TotalsPerCategoryPerDay[]): PointOptionsObject[] {
        const dataset = [];
        const dates = this.getEpochsBetweenDates(this.dateRange.from.toDate(), this.dateRange.until.toDate());

        stats.forEach(stat => {
            const urgency = stat.totalPerUrgency.all;
            const color = ThemeColors.Orange;
            const categoryNameKey = stat.category;
            // name: will be used as tooltip display, to sneakily inject more stats in the tooltip
            const tooltip = `Category: ${categoryNameKey}<br>Major urgency : ${stat.totalPerUrgency.major}<br>Moderate urgency : ${stat.totalPerUrgency.moderate}<br>Minor urgency : ${stat.totalPerUrgency.minor}<br>`;
            const y_categoryId = this.lookupThreatTypeId(categoryNameKey);
            if (y_categoryId === -1) return;
            // merge it
            dataset.push({
                x: stat.date.getTime(),
                y: y_categoryId,
                value: urgency,
                name: tooltip,
                color
            });
        });

        // add missing dates between the first and last date
        dates.forEach((d) => {
            const existingPointsPerDay = dataset.filter((x) => x.x === d);

            this.threatCategories.forEach((cat, idx) => {
                // if no point exists for day-x, add in a '0'
                if (!existingPointsPerDay.find((x) => x.y === idx))
                    dataset.push({
                        x: d,
                        y: idx,
                        value: 0,
                        name: `No threats detected for ${cat}`,
                        color: Utilities.getUrgencyColor(0)
                    });
            });
        });

        return dataset;
    }
}
