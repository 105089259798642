import { bindable } from 'aurelia-framework';

export class Accordion {

    @bindable() private expand: boolean = true;
    private accordionWrapper: HTMLElement;
    private contentWrapper: HTMLElement;

    /**
     * Set this to specify the exact height of the accordion when expanded.
     * If this property is not set, the accordion will size to the children inside.
     */
    @bindable() private expandHeight: number;
}
