import { bindable } from 'aurelia-framework';
import moment from 'moment';
import { ThemeColors } from 'resources/theme/theme-colors';
import { ArrayUtilities } from 'utilities/array-utilities';
import { ISicAlertOccurrence } from 'services/cyber-api';
import Highcharts from 'highcharts';

export class OccurrencesChart {
    @bindable() private occurrences: ISicAlertOccurrence[];
    @bindable() private onFilter: (args: { day: moment.Moment, hour: moment.Moment }) => void;
    private series: Highcharts.SeriesColumnOptions[];
    private drilldown: { series: Highcharts.SeriesColumnOptions[] };
    private options: Highcharts.Options = {
        chart: {
            zooming: {
                type: null
            },
            events: {
                drilldown: (event: Highcharts.DrilldownEventObject) => {
                    const series = event.seriesOptions;
                    const timestamp = series.id;
                    const day = moment(timestamp);

                    // Notify the consumer of this component that there has been filtered by a day
                    if (this.onFilter)
                        this.onFilter({ day, hour: undefined });
                },
                drillup: (event: Highcharts.DrillupEventObject) => {
                    // Notify the consumer of this component that the filter has been cleared
                    if (this.onFilter)
                        this.onFilter({ day: undefined, hour: undefined });
                }
            }
        },
        legend: {
            enabled: false
        },
        yAxis: {
            title: {
                enabled: false
            } as any,
            minRange: 5,
            min: 0,
            allowDecimals: false
        },
        xAxis: {
            type: 'datetime',
            labels: {
                formatter() {
                    // When the value is not the start of the day, we know the user has drilled down
                    if (moment(this.value).startOf('day').valueOf() === this.value)
                        return moment(this.value).format('D. MMM');
                    else
                        // For drilled down display, we want to the x axis labels as hours
                        return moment(this.value).format('H');
                },
                style: {
                    'text-decoration': 'none !important',
                    'fill': '#666666 !important', // TODO: find a way to color the link text...
                    'color': '#666666 !important',
                } as any
            }
        },
        tooltip: {
            formatter() {
                return `
                    <strong>${this.point.name}</strong>:
                    <span>${this.point.y} occurrence${this.point.y > 1 ? 's' : ''}</span>
                `;
            }
        },
        plotOptions: {
            column: {
                pointWidth: 15,
                borderRadius: 6,
                color: '#cecece',
                states: {
                    hover: {
                        color: '#9d9d9d'
                    },
                    select: {
                        color: ThemeColors.Orange,
                        borderColor: ThemeColors.Orange
                    }
                } as any,
                cursor: 'pointer',
                allowPointSelect: true,
                point: {
                    events: {
                        select: (event: any) => {
                            const timestamp = event.target.x;
                            const hour = moment(timestamp);

                            // Notify the consumer of this component that there has been filtered by a day
                            if (this.onFilter)
                                setTimeout(() => {
                                    this.onFilter({ day: undefined, hour });
                                });
                        },
                        unselect: (event: any) => {
                            // Only trigger the filter when the column is being unselected manually.
                            // When the target is not selected, it means a different column was selected.
                            if (!event.target.selected) return;

                            const timestamp = event.target.x;
                            const hour = moment(timestamp);
                            const day = hour.startOf('day');

                            // Notify the consumer of this component that there has been filtered by a day
                            if (this.onFilter)
                                setTimeout(() => {
                                    this.onFilter({ day, hour: undefined });
                                });
                        }
                    }
                }
            }
        }
    };

    public async refresh(): Promise<void> {
        if (this.occurrences.length > 0) {
            [this.series, this.drilldown] = this.mapSeries();
        } else {
            this.series = [];
            this.drilldown = {series: []};
        }

    }

    private bind(): void {
        this.refresh();
    }

    private async occurrencesChanged(): Promise<void> {
        await this.refresh();
    }

    private mapSeries(): [Highcharts.SeriesColumnOptions[], { series: Highcharts.SeriesColumnOptions[] }] {
        const groupedPerDay = ArrayUtilities.groupBy(
            this.occurrences,
            (x) => moment(x.createdAt.valueOf()).startOf('day').valueOf()
        );
        const seriesData = [];
        const drilldownSeries = [];

        for (const [dayTimestamp, dayGroup] of groupedPerDay) {

            const dayDateFormatted = moment(dayTimestamp).format('D. MMM');

            seriesData.push({
                name: dayDateFormatted,
                x: dayTimestamp,
                y: dayGroup.length,
                drilldown: dayTimestamp
            });

            const groupedPerHour = ArrayUtilities.groupBy(
                dayGroup,
                (x) => moment(x.createdAt.valueOf()).startOf('hour').valueOf()
            );
            const drilldownSeriesData = [];

            for (const [hourTimestamp, hourGroup] of groupedPerHour) {
                const hourDateFormatted = moment(hourTimestamp).format('H');

                drilldownSeriesData.push({
                    name: hourDateFormatted,
                    x: hourTimestamp,
                    y: hourGroup.length
                });
            }

            drilldownSeries.push({
                name: dayDateFormatted,
                id: dayTimestamp,
                data: drilldownSeriesData
            });
        }

        const series = [
            {
                name: 'daily',
                data: seriesData
            }
        ] as Highcharts.SeriesColumnOptions[];

        const drilldownOptions = {
            series: drilldownSeries
        };

        return [series, drilldownOptions];
    }
}
