import { autoinject, bindable, containerless } from 'aurelia-framework';
import { IDateRange } from 'components/charts/range-filter/range-filter';
import { StatsApiClient, TotalPerTtp } from 'services/cyber-api';
import { StateApi } from 'services/state-api';

@autoinject()
@containerless()
export class TopThreats {
    @bindable() private dateRange: IDateRange;
    private loading: boolean = true;
    private totalsPerTtps: TotalPerTtp[];

    constructor(
        private state: StateApi,
        private statsApi: StatsApiClient
    ) { }

    private async dateRangeChanged(): Promise<void> {
        this.loading = true;

        this.totalsPerTtps = await this.statsApi.totalsPerTtps(this.state.company(), this.dateRange.from.toDate(), this.dateRange.until.toDate(), 5);

        this.loading = false;
    }
}

// tslint:disable-next-line: max-classes-per-file
export class TotalsWidthValueConverter {
    private toView(value: number, values: TotalPerTtp[]): number {
        // Find the all value of the category with the biggest all value
        const biggestCountValue = Math.max(...values.map((v) => v.count));

        // Calculate the percentage of the current category's all relative to the biggest category's all value
        const percentage = (value / biggestCountValue) * 100;

        // Return the percentage width value
        return percentage;
    }
}
