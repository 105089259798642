import { bindable } from 'aurelia-framework';
import moment from 'moment';

export class RangeFilter {
    private ranges: IDateRange[] = [
        // '6m' crashes sekoia-api
        // {
        //     name: '6m',
        //     from: moment().subtract(6, 'months'),
        //     until: moment().endOf('day')
        // },
        {
            name: '1m',
            previousFrom: moment().utc().subtract(2, 'month').endOf('day'),
            from: moment().utc().subtract(1, 'month').endOf('day'),
            until: moment().utc().endOf('day')
        }, {
            name: '1w',
            previousFrom: moment().utc().subtract(2, 'week'),
            from: moment().utc().subtract(1, 'week'),
            until: moment().utc().endOf('day')
        }, {
            name: '1d',
            previousFrom: moment().utc().subtract(1, 'day').startOf('day'),
            from: moment().utc().startOf('day'),
            until: moment().utc().endOf('day')
        }
    ];
    @bindable() private range: IDateRange = this.ranges[1];
    @bindable() private onChange: (range: IDateRange) => {};

    private selectRange(range: IDateRange): void {
        this.range = range;

        if (this.onChange)
            this.onChange(this.range);
    }
}

export interface IDateRange {
    name: string;
    previousFrom: moment.Moment;
    from: moment.Moment;
    until: moment.Moment;
}
