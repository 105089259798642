import { bindable } from 'aurelia-framework';
import { HighchartsBaseComponent } from '../highcharts-base-component';
import { HighchartsDefaults } from '../highcharts-defaults';

export class PieChart extends HighchartsBaseComponent {
    @bindable private minHeight: number = 400;
    @bindable private minWidth: number;

    protected configure(): Highcharts.Options {
        const defaultOptions = HighchartsDefaults.pieChartOptions;

        const options: Highcharts.Options = {
            ...defaultOptions,
            ...this.options,
            chart: {
                ...defaultOptions.chart,
                renderTo: this.container,
                ...this.options.chart
            },
            xAxis: {
                ...defaultOptions.xAxis,
                lineWidth: 0,
                ...this.options.xAxis
            },
            plotOptions: {
                ...defaultOptions.plotOptions,
                pie: {
                    ...defaultOptions.plotOptions.pie,
                    ...this.options.plotOptions.pie
                },
                ...this.options.plotOptions
            },
            series: this.series
        };

        return options;
    }
}
