import { bindable } from 'aurelia-templating';
import Highcharts from 'highcharts';
import { HighchartsBaseComponent } from '../highcharts-base-component';
import { HighchartsDefaults } from '../highcharts-defaults';

export class SplineChart extends HighchartsBaseComponent {
    @bindable public units: string;

    protected configure(): Highcharts.Options {
        const defaultOptions = HighchartsDefaults.baseOptions;
        const options: Highcharts.Options = {
            ...defaultOptions,
            ...this.options,
            chart: {
                ...defaultOptions.chart,
                type: 'spline',
                renderTo: this.container,
                ...this.options.chart
            },
            series: this.series
        };

        return options;
    }
}
