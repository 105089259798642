import { bindable } from 'aurelia-framework';
import { HighchartsBaseComponent } from '../highcharts-base-component';
import { HighchartsDefaults } from '../highcharts-defaults';

export class LineChart extends HighchartsBaseComponent {
    @bindable() private units: string;

    protected configure(): Highcharts.Options {
        const defaultOptions = HighchartsDefaults.lineChartOptions;
        const options: Highcharts.Options = {
            ...defaultOptions,
            ...this.options,
            chart: {
                ...defaultOptions.chart,
                renderTo: this.container,
                ...this.options.chart
            },
            plotOptions: {
                ...defaultOptions.plotOptions,
                series: {
                    ...defaultOptions.plotOptions.series,
                    lineWidth: 2,
                    ...this.options.plotOptions.series
                },
                ...this.options.plotOptions
            },
            series: this.series
        };

        return options;
    }
}
