import { bindable } from 'aurelia-templating';
import Highcharts from 'highcharts';
import HighchartsHeatmap from 'highcharts/modules/heatmap';
import { HighchartsBaseComponent } from '../highcharts-base-component';
import { HighchartsDefaults } from '../highcharts-defaults';
HighchartsHeatmap(Highcharts);

export class HeatmapChart extends HighchartsBaseComponent {
    @bindable() private units: string;

    protected configure(): Highcharts.Options {
        const defaultOptions = HighchartsDefaults.heatMapChartOptions;
        const options: Highcharts.Options = {
            ...defaultOptions,
            ...this.options,
            chart: {
                ...defaultOptions.chart,
                renderTo: this.container,
                ...this.options.chart
            },
            xAxis: {
                ...defaultOptions.xAxis,
                gridLineWidth: 0,
                startOnTick: false,
                endOnTick: false,
                lineWidth: 2,
                minPadding: 0.03, // 3% padding to the left of the first value on x-axis to give it room from the y-axis
                lineColor: 'whitesmoke',
                ...this.options.xAxis
            },
            yAxis: {
                ...defaultOptions.yAxis,
                gridLineWidth: 0,
                allowDecimals: false,
                title: null,
                lineWidth: 2,
                lineColor: 'whitesmoke',
                plotLines: [],
                ...this.options.yAxis
            },
            legend: {
                ...defaultOptions.legend,
                enabled: false,
                ...this.options.legend
            },
            series: this.series
        };

        return options;
    }
}
