import { autoinject, bindable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { ThemeColors } from 'resources/theme/theme-colors';
import { LatestVesselRisk, StatsApiClient } from 'services/cyber-api';
import { StateApi } from 'services/state-api';
import { Toast } from 'utilities/toast';

@autoinject()
export class FleetRiskLevelIndicator {
    @bindable() private latestVesselRisk: LatestVesselRisk[];
    @bindable() public riskLevelPercentages: {
        high: number,
        medium: number,
        low: number,
        norisk: number
    };
    @bindable() private subtitle: string = 'Average Risk Score';
    @bindable() private height: number = 350;
    @bindable() private width: number;
    @bindable() private titleSize: string = '52pt';
    @bindable() private subtitleSize: string = '12pt';
    @bindable() private innerSize: number = 70;
    @bindable() private titleYOffset: number = 35;

    private series: Highcharts.SeriesPieOptions[];
    private options: Highcharts.Options = {
        chart: {
            zooming: {
                type: null
            }
        },
        legend: {
            enabled: false
        },
        plotOptions: {
            pie: {
                size: '100%',
                innerSize: `${this.innerSize}%`,
                dataLabels: {
                    enabled: false
                }
            }
        }
    };

    constructor(
        private stateApi: StateApi,
        private statsApi: StatsApiClient,
        private router: Router
    ) {
    }

    private async latestVesselRiskChanged(): Promise<void> {
        await this.refresh();
    }

    private async refresh(): Promise<void> {
        // Fetch the risk of the entire fleet and use it as chart title
        const risk = await this.getAverageRisk();

        // The available risk categories
        const riskCategoryCount = {
            high: 0,
            medium: 0,
            low: 0,
            norisk: 0
        };

        // Count the amount of times the risk falls in each of the categories
        this.latestVesselRisk.forEach((risk) => {
            const { currentRisk } = risk;

            if (currentRisk >= 66) {
                riskCategoryCount.high++;
            } else if (currentRisk > 33) {
                riskCategoryCount.medium++;
            } else if (currentRisk > 0) {
                riskCategoryCount.low++;
            } else if (currentRisk === 0 || currentRisk === null) {
                riskCategoryCount.norisk++;
            }
        });
        // Convert the category counts to percentages of the total stats
        this.riskLevelPercentages = {
            high: (riskCategoryCount.high / this.latestVesselRisk.length) * 100,
            medium: (riskCategoryCount.medium / this.latestVesselRisk.length) * 100,
            low: (riskCategoryCount.low / this.latestVesselRisk.length) * 100,
            norisk: (riskCategoryCount.norisk / this.latestVesselRisk.length) * 100
        };

        // Set dynamic options, such as title + subtitle
        this.options = {
            ...this.options,
            title: {
                text: risk === '0' ? '-' : risk.toString(),
                verticalAlign: 'middle',
                floating: true,
                style: {
                    color: ThemeColors.DarkGrey,
                    fontSize: this.titleSize
                },
                y: Number(this.titleYOffset)
            }
        };

        // Add subtitle if it's defined
        if (this.subtitle)
            this.options = {
                ...this.options,
                subtitle: {
                    text: this.subtitle.toUpperCase(),
                    verticalAlign: 'middle',
                    floating: true,
                    style: {
                        color: ThemeColors.DarkerGrey,
                        fontSize: this.subtitleSize
                    },
                    y: Number(this.titleYOffset)
                },
            };

        this.series = this.mapSeries();
    }

    private async getAverageRisk(): Promise<string> {
        try {
            const vesselRisks = await this.statsApi.latestVesselsRisk(this.stateApi.company());
            // Calculate the average risk of the fleet
            const statsOnThreats = vesselRisks.reduce((acc, vesselRisk) => {
                acc.risk += vesselRisk.currentRisk ?? 0;
                return acc;
            }, { risk: 0 });
            return ((statsOnThreats?.risk / vesselRisks.length  ?? 0)).toFixed(0);
        } catch (error) {
            Toast.statsApiError();
            return 'N/A';
        }
    }

    private mapSeries(): Highcharts.SeriesPieOptions[] {
        return [
            {
                name: 'Risk',
                data: [
                    {
                        color: ThemeColors.Red,
                        name: 'HIGH RISK',
                        y: this.riskLevelPercentages.high
                    },
                    {
                        color: ThemeColors.Orange,
                        name: 'MEDIUM RISK',
                        y: this.riskLevelPercentages.medium
                    },
                    {
                        color: ThemeColors.Yellow,
                        name: 'LOW RISK',
                        y: this.riskLevelPercentages.low
                    },
                    {
                        color: ThemeColors.Green,
                        name: 'NO RISK',
                        y: this.riskLevelPercentages.norisk
                    }
                ],
                point: {
                    events: {
                        click: (event: any): boolean => {
                            // Retrieve the threat category from the slice name
                            const sliceName: string = event.point.name.toLowerCase();
                            const riskCategory = sliceName.split(' ')[0];

                            this.router.navigateToRoute('vessels', { risk: riskCategory });

                            return true;
                        }
                    }
                },
                cursor: 'pointer',
                type: 'pie'
            }
        ];
    }
}
