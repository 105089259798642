import { autoinject, bindable, containerless } from 'aurelia-framework';
import { CaseBookLightweight, CaseBookLightweightExtended, CaseBookStatusTypes, CasesApiClient, SortableCasesFields } from 'services/cyber-api';
import { StateApi } from 'services/state-api';

@autoinject()
@containerless()
export class CasesDropdown {
    @bindable({ changeHandler: 'filterByEntityId' }) private entityId: string;
    @bindable() private selectHandler: ({ caseBook }) => void;
    @bindable() private createHandler: () => void;
    private cases: CaseBookLightweight[] = [];
    private filteredCases: CaseBookLightweight[] = [];
    private totalCasesCount: number;
    private readonly take: number = 10;
    private skip: number = 0;
    private loading: boolean = true;

    constructor(
        private casesApi: CasesApiClient,
        private state: StateApi
    ) { }

    private async bind(): Promise<void> {
        this.resetCases();
    }

    private async resetCases(): Promise<void> {
        this.cases = [];
        this.skip = 0;
        this.totalCasesCount = undefined;
        await this.fetchCases(0, true, false);
    }

    private async fetchCases(topIndex: number, isAtBottom: boolean, isAtTop: boolean): Promise<void> {
        // Only fetch more when scroll position is at the bottom
        if (!isAtBottom) return;

        // When at the end of the list and no more cases are available, short-circuit as there's nothing left
        // to fetch
        if (this.totalCasesCount === this.cases.length) return;

        this.loading = true;

        const pagedCases = await this.casesApi.getAllLightweight(this.state.company(),
            undefined,
            this.take,
            this.skip,
            SortableCasesFields.Updated_at,
            'desc',
            undefined,
            undefined,
            CaseBookStatusTypes.Open
        );

        if (!pagedCases) return;

        this.cases = this.cases.concat(pagedCases.items);
        this.filterByEntityId();

        this.totalCasesCount = pagedCases.total;

        this.skip += this.take;

        this.loading = false;
    }

    private filterByEntityId(): void {
        if (!this.cases) return;
        if (!this.entityId) this.filteredCases = this.cases;

        this.filteredCases = this.cases.filter((c) => c.referencedEntities.every((re) => re.entityId !== this.entityId));
    }

    private doClick(caseBook: CaseBookLightweightExtended): void {
        if (this.selectHandler !== undefined)
            this.selectHandler({ caseBook });
    }

    private doCreate(): void {
        if (this.createHandler !== undefined)
            this.createHandler();
    }
}
