import { bindable } from 'aurelia-framework';
import moment from 'moment';
import { ThemeColors } from 'resources/theme/theme-colors';

const urgencyLevels = ['None', 'Low', 'Severe', 'Critical'];

export class UrgencyChart {
    @bindable() private threatId: string;
    private series: Highcharts.SeriesSplineOptions[];
    private options: Highcharts.Options = {
        yAxis: {
            title: null,
            labels: {
                formatter() {
                    return urgencyLevels[this.value];
                }
            }
        },
        colorAxis: {
            dataClasses: [
                {
                    from: 0,
                    to: 1,
                    color: 'lightgray'
                }, {
                    from: 1,
                    to: 2,
                    color: 'yellow'
                }, {
                    from: 2,
                    to: 10,
                    color: ThemeColors.Orange
                }, {
                    from: 10,
                    color: ThemeColors.Red
                }
            ]
        },
        legend: {
            enabled: false
        },
        tooltip: {
            formatter() {
                const urgency = urgencyLevels[this.y];
                return `<strong>${urgency}</strong> level<br>on ${moment(new Date(this.x)).format('Do MMM YYYY hh:mm')}`;
            }
        },
        plotOptions: {
            spline: {
                marker: {
                    enabled: false
                }
            }
        },
    };

    private async bind(): Promise<void> {
        await this.refresh();
    }

    private async threatIdChanged(newThreatId: string): Promise<void> {
        await this.refresh();
    }

    private async refresh(): Promise<void> {
        // TODO: retrieve urgency data

        this.series = this.mapSeries();
    }

    private mapSeries(): Highcharts.SeriesSplineOptions[] {
        return [{
            name: 'Urgency',
            data: [{
                x: moment().valueOf(),
                y: 0
            }, {
                x: moment().add(12, 'hours').valueOf(),
                y: 1
            }, {
                x: moment().add(24, 'hours').valueOf(),
                y: 1
            }, {
                x: moment().add(36, 'hours').valueOf(),
                y: 3
            }, {
                x: moment().add(48, 'hours').valueOf(),
                y: 2
            }, {
                x: moment().add(60, 'hours').valueOf(),
                y: 2
            }, {
                x: moment().add(72, 'hours').valueOf(),
                y: 1
            }, {
                x: moment().add(84, 'hours').valueOf(),
                y: 1
            }, {
                x: moment().add(96, 'hours').valueOf(),
                y: 1
            }, {
                x: moment().add(108, 'hours').valueOf(),
                y: 1
            }, {
                x: moment().add(120, 'hours').valueOf(),
                y: 0
            }, {
                x: moment().add(132, 'hours').valueOf(),
                y: 1
            }, {
                x: moment().add(144, 'hours').valueOf(),
                y: 1
            }],
            type: 'spline'
        }];
    }
}
