import { bindable } from 'aurelia-framework';
import { HighchartsBaseComponent } from '../highcharts-base-component';
import { HighchartsDefaults } from '../highcharts-defaults';

export class ColumnChart extends HighchartsBaseComponent {
    @bindable private minHeight: number = 400;
    @bindable private minWidth: number;

    protected configure(): Highcharts.Options {
        const defaultOptions = HighchartsDefaults.columnChartOptions;

        const options: Highcharts.Options = {
            ...defaultOptions,
            ...this.options,
            chart: {
                ...defaultOptions.chart,
                ...this.options.chart,
                renderTo: this.container
            },
            series: this.series,
            drilldown: {
                ...defaultOptions.drilldown,
                ...this.drilldown
            } as Highcharts.DrilldownOptions,
            plotOptions: {
                ...defaultOptions.plotOptions,
                ...this.options.plotOptions
            }
        };

        return options;
    }
}
