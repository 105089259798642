import { autoinject, bindable, containerless } from 'aurelia-framework';
import {
    EdrApiClient, GetAgentVersionCountResponse,
    ICompanyLightweight,
    IGetAgentVersionCountResponse
} from 'services/cyber-api';
import { StateApi } from 'services/state-api';

@autoinject()
@containerless()
export class edrVersionSiteWidget {
    @bindable() private siteId: string;
    private edrStats: IGetAgentVersionCountResponse[];
    private company: ICompanyLightweight;

    constructor(
        private state: StateApi,
        private edrApi: EdrApiClient
    ) {
    }

    private async attached(): Promise<void> {
        const companyState = this.state.company();
        // Retrieve companies from the state cache
        const companies = this.state.companies() || [];
        this.company = companies.find((c) => c.id === companyState);
        if (this.company.isEdrActive)
            this.edrApi.agentVersionCountPerSite(this.siteId, this.state.company())
                .then((result) => {
                    // Sort by count descending in case sorting isn't right
                    result.sort((a, b) => b.count - a.count);

                    this.edrStats = result;
                });
        else
            this.edrStats = [
                new GetAgentVersionCountResponse({
                    version: '0.0.0',
                    count: 0
                }),
                new GetAgentVersionCountResponse({
                    version: '0.0.0',
                    count: 0
                })
            ]
    }
}
