import { autoinject, bindable, containerless } from 'aurelia-framework';
import { StatsApiClient, TotalsPerUrgencyAndCategory, TotalsPerUrgencyAndCategoryAndSite } from 'services/cyber-api';
import { StateApi } from 'services/state-api';

@autoinject()
@containerless()
export class UrgencyDistribution {
    @bindable() private siteId: string;
    private loading: boolean = true;
    private categoryTotals: TotalsPerUrgencyAndCategory[] | TotalsPerUrgencyAndCategoryAndSite[];
    private grandTotals: IGrandTotals;

    constructor(
        private statsApi: StatsApiClient,
        private state: StateApi
    ) { }

    private async attached(): Promise<void> {
        let categoryTotals: TotalsPerUrgencyAndCategory[] | TotalsPerUrgencyAndCategoryAndSite[];

        // If specified, retrieve totals by site id
        if (this.siteId)
            categoryTotals = await this.statsApi.totalsPerCategoryAndSite(this.siteId, this.state.company());
        // Otherwise, retrieve totals for entire company
        else
            categoryTotals = await this.statsApi.totalsPerCategory(this.state.company());

        this.categoryTotals = categoryTotals.sort((ct1, ct2) => ct2.total_per_urgency.all - ct1.total_per_urgency.all);
        // Calculate the grand totals, adding up each of the categories' totals to one big number per urgency range
        this.grandTotals = this.extractGrandTotals(this.categoryTotals);

        this.loading = false;
    }

    /**
     * Extracts grand total amount of threats per urgency across all categories combined.
     * @param categoryTotals List of category totals containing amount of threats per urgency for that category
     */
    private extractGrandTotals(categoryTotals: TotalsPerUrgencyAndCategory[]): IGrandTotals {
        return categoryTotals.reduce((grandTotals: any, categoryTotal) => {
            grandTotals.all += categoryTotal.total_per_urgency.all;
            grandTotals.major += categoryTotal.total_per_urgency.major;
            grandTotals.moderate += categoryTotal.total_per_urgency.moderate;
            grandTotals.minor += categoryTotal.total_per_urgency.minor;

            return grandTotals;
        }, {
            all: 0,
            major: 0,
            moderate: 0,
            minor: 0
        });
    }
}

interface IGrandTotals {
    all: number;
    major: number | undefined;
    moderate: number | undefined;
    minor: number | undefined;
}

// tslint:disable-next-line: max-classes-per-file
export class CategoryWidthValueConverter {
    private toView(value: TotalsPerUrgencyAndCategory, values: TotalsPerUrgencyAndCategory[]): number {
        // Find the all value of the category with the biggest all value
        const biggestCategoryAllValue = Math.max(...values.map((v) => v.total_per_urgency.all));

        // Calculate the percentage of the current category's all relative to the biggest category's all value
        const percentage = (value.total_per_urgency.all / biggestCategoryAllValue) * 100;

        // Return the percentage width value
        return percentage;
    }
}
