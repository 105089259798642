import Highcharts from 'highcharts';
import { ThemeColors } from 'resources/theme/theme-colors';

// Set the global Highcharts options, which should be set using the .setOptions function
// instead of in the actual options object, see https://api.highcharts.com/highcharts/global
Highcharts.setOptions({
    global: {
    },
    time: {
    },
    lang: {
        decimalPoint: '.',
        thousandsSep: ','
    }
});

export class HighchartsDefaults {
    public static labelColor: string = '#d3d3d3';
    public static options = Highcharts.getOptions();

    public static colors: string[] = [
        ThemeColors.Red,
        ThemeColors.Orange
    ];

    public static baseOptions: Highcharts.Options = {
        ...HighchartsDefaults.options,
        chart: {
            ...HighchartsDefaults.options.chart,
            backgroundColor: 'transparent',
            resetZoomButton: {
                ...HighchartsDefaults.options.chart.resetZoomButton,
                position: {
                    ...HighchartsDefaults.options.chart.resetZoomButton.position,
                    x: 0,
                    y: -1
                },
                theme: {
                    zIndex: 6
                } as any
            },
            zooming: {
                type: 'xy'
            },
            panning: {
                enabled: true
            },
            panKey: 'shift',
            showAxes: true,
            style: {
                fontFamily: 'Roboto',
                fontSize: '12pt'
            }
        },
        colors: HighchartsDefaults.colors,
        credits: {
            ...HighchartsDefaults.options.credits,
            enabled: false
        },
        legend: {
            ...HighchartsDefaults.options.legend,
            align: 'right',
            borderWidth: 0,
            itemHoverStyle: {
                ...HighchartsDefaults.options.legend.itemHoverStyle,
                color: '#333'
            },
            itemStyle: {
                ...HighchartsDefaults.options.legend.itemStyle,
                color: '#111'
            },
            layout: 'horizontal',
            verticalAlign: 'bottom'
        },
        tooltip: {
            ...HighchartsDefaults.options.tooltip,
            shared: true
        },
        plotOptions: {
            ...HighchartsDefaults.options.plotOptions,
            area: {
                ...HighchartsDefaults.options.plotOptions.area,
                lineWidth: 1,
                marker: {
                    ...HighchartsDefaults.options.plotOptions.area.marker,
                    radius: 3
                },
                states: {
                    ...HighchartsDefaults.options.plotOptions.area.states,
                    hover: {
                        ...HighchartsDefaults.options.plotOptions.area.states.hover as Highcharts.SeriesStatesHoverOptionsObject,
                        // line width 2 looks nicer
                        // but flickers due to shared tooltip
                        // TODO: check with highcharts support
                        // if the linewidth can be increased
                        // for both lines when using shared tooltip
                        lineWidth: 1
                    }
                },
                threshold: null
            },
            areaspline: {
                ...HighchartsDefaults.options.plotOptions.areaspline,
                fillOpacity: 0
            },
            series: {
                ...HighchartsDefaults.options.plotOptions.series,
                findNearestPointBy: 'xy'
            }
        },
        subtitle: {
            ...HighchartsDefaults.options.subtitle,
            text: null
        },
        title: {
            ...HighchartsDefaults.options.title,
            text: null
        },
        xAxis: {
            ...HighchartsDefaults.options.xAxis,
            title: {
                text: 'Date',
                align: 'high',
                style: {
                    color: HighchartsDefaults.labelColor
                }
            } as Highcharts.AxisOptions,
            type: 'datetime',
            startOnTick: true,
            endOnTick: true,
            showFirstLabel: true,
            showLastLabel: true,
            tickInterval: 24 * 3600 * 1000, // Set label interval to 1 day, in milliseconds
        },
        yAxis: {
            ...HighchartsDefaults.options.yAxis,
            gridLineColor: HighchartsDefaults.labelColor,
            gridLineWidth: 0.5,
            startOnTick: true,
            endOnTick: true,
            showFirstLabel: true,
            showLastLabel: true.toString(),
            // min-range: if the y-axis values are 0, or close to 0,
            // the line is drawn in the middle and it appears that
            // there is a lot of traffic. setting a minRange fixes this.
            // see also: https://marlink.myjetbrains.com/youtrack/issue/MM-7
            // minRange: 0,
            plotLines: [{
                color: HighchartsDefaults.labelColor,
                value: 0,
                width: 1,
            }],
            title: {
                style: {
                    color: HighchartsDefaults.labelColor
                },
                text: 'units'
            }
        }
    };

    public static heatMapChartOptions: Highcharts.Options = {
        ...HighchartsDefaults.baseOptions,
        chart: {
            ...HighchartsDefaults.baseOptions.chart,
            type: 'heatmap'
        }
    };

    public static pieChartOptions: Highcharts.Options = {
        ...HighchartsDefaults.baseOptions,
        chart: {
            ...HighchartsDefaults.baseOptions.chart,
            margin: [0, 0, 0, 0],
            plotBackgroundColor: null,
            plotBackgroundImage: null,
            plotBorderWidth: null,
            plotShadow: false,
            spacingTop: 0,
            spacingRight: 0,
            spacingBottom: 0,
            spacingLeft: 0,
            type: 'pie'
        },
        credits: {
            enabled: false
        },
        exporting: {
            enabled: false
        },
        legend: {
            align: 'right',
            borderColor: '#111',
            borderWidth: 0,
            itemHoverStyle: {
                color: '#bbb'
            },
            itemStyle: {
                background: 'none',
                color: '#111'
            },
            layout: 'vertical',
            verticalAlign: 'top',
            x: 0,
            y: 0
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                borderColor: '#fff',
                colors: HighchartsDefaults.colors,
                cursor: 'pointer',
                dataLabels: {
                    enabled: true,
                    format: '<b>{point.name}</b>: {point.percentage:.1f} %',
                    style: {
                        color: '#111',
                        fontSize: '10px'
                    }
                },
                showInLegend: true,
                center: ['50%', '50%']
            }
        },
        title: {
            text: ''
        },
        tooltip: {
            headerFormat: '',
            pointFormat: `{point.name}: <strong>{point.y:.1f}%</strong>`
        }
    };

    public static lineChartOptions: Highcharts.Options = {
        ...HighchartsDefaults.baseOptions,
        chart: {
            ...HighchartsDefaults.baseOptions.chart,
            type: 'line'
        }
    };

    public static columnChartOptions: Highcharts.Options = {
        ...HighchartsDefaults.baseOptions,
        chart: {
            ...HighchartsDefaults.baseOptions.chart,
            type: 'column'
        },
        plotOptions: {
            column: {
                allowPointSelect: true,
                colors: HighchartsDefaults.colors,
                cursor: 'pointer',
                showInLegend: true,
                tooltip: {
                    headerFormat: '',
                    pointFormat: '{point.name}: <strong>{point.y:.1f}%</strong>'
                }
            }
        }
        // TODO: 'drilldown' errors on upgrade to highcharts 7.x. Commented out for now.
        // drilldown: {
        //     drillUpButton: {
        //         relativeTo: 'spacingBox',
        //         theme: {
        //             fill: ThemeColors.LighterGrey,
        //             stroke: ThemeColors.LighterGrey,
        //             style: {
        //                 'font-size': '0.83125rem',
        //                 'font-weight': '300'
        //             }
        //         }
        //     }
        // }
    };
}
