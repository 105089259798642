import { bindable } from 'aurelia-framework';
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsDrilldown from 'highcharts/modules/drilldown';
import HighchartsHeatmap from 'highcharts/modules/heatmap';
import HighchartsAccessibility from 'highcharts/modules/accessibility';

HighchartsMore(Highcharts);
HighchartsHeatmap(Highcharts);
HighchartsDrilldown(Highcharts);
HighchartsAccessibility(Highcharts);

/**
 * Represents the base class for business components,
 * encapsulating reusable plumbing.
 */
export abstract class HighchartsBaseComponent {

    @bindable() protected series: Highcharts.SeriesOptionsType[];
    @bindable() protected drilldown: { series: Highcharts.SeriesOptions[] };
    @bindable() protected emptyMessage: string = 'No data found';
    @bindable() protected options: Highcharts.Options = {};
    protected chart: Highcharts.Chart;
    protected readonly container: HTMLElement;

    public async reload(): Promise<void> {
        const config = this.configure();
        this.chart = new Highcharts.Chart(config);
    }

    /**
     * Requires implementation to setup the highcharts configuration (options).
     */
    protected abstract configure(): Highcharts.Options;

    protected async attached() {
        this.reload();
    }

    protected seriesChanged(newSeries: Highcharts.SeriesOptions[]): void {
        if (this.container)
            this.reload();
    }
}
